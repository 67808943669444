<template>
  <div>
    <kn-form-subtitle title="Agregar Descuento pronto pago" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Nombre descuento:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="promptPaymentDiscount.nombre_descuento"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0" cols="4">
          <v-subheader>Porcentaje de descuento:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model.number="promptPaymentDiscount.porcentaje_descuento"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Fecha inicio:</v-subheader>
        </v-col>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="8"
          xl="4"
        >
          <v-text-field
            v-model="promptPaymentDiscount.fecha_inicio"
            dense
            outlined
            :rules="[rules.required]"
            type="date"
            hint="DD / MM / YYYY"
            persistent-hint
          >
            <template v-slot:append-outer>
              <v-icon color="primary">mdi-calendar-month-outline</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Fecha fin:</v-subheader>
        </v-col>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="8"
          xl="4"
        >
          <v-text-field
            v-model="promptPaymentDiscount.fecha_fin"
            dense
            outlined
            :rules="[rules.required]"
            type="date"
            hint="DD / MM / YYYY"
            persistent-hint
          >
            <template v-slot:append-outer>
              <v-icon color="primary">mdi-calendar-month-outline</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de descuentos pronto pago"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activePromptPaymentDiscounts">
          <v-list-item :key="item.nombre_descuento">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === promptPaymentDiscount.id"
                  class="mb-0 pb-0"
                  v-model="promptPaymentDiscount.nombre_descuento"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre_descuento"
                />
                <v-text-field
                  v-if="toUpdate && item.id === promptPaymentDiscount.id"
                  class="mb-0 pb-0"
                  v-model="promptPaymentDiscount.porcentaje_descuento"
                  dense
                  outlined
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Porcentaje de descuento: ${item.porcentaje_descuento * 100}%`"
                />
                <v-text-field
                  v-if="toUpdate && item.id === promptPaymentDiscount.id"
                  v-model="promptPaymentDiscount.fecha_inicio"
                  dense
                  outlined
                  type="date"
                  hint="DD / MM / YYYY"
                  persistent-hint
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                  </template>
                </v-text-field>
                <v-list-item-subtitle
                  v-else
                  v-text="`Inicio: ${getDateWithTimeZeroFullStr(item.fecha_inicio)}`"
                />
                <v-text-field
                  v-if="toUpdate && item.id === promptPaymentDiscount.id"
                  v-model="promptPaymentDiscount.fecha_fin"
                  dense
                  outlined
                  type="date"
                  hint="DD / MM / YYYY"
                  persistent-hint
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                  </template>
                </v-text-field>
                <v-list-item-subtitle
                  v-else
                  v-text="`Fin: ${getDateWithTimeZeroFullStr(item.fecha_fin)}`"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === promptPaymentDiscount.id"
                  no-gutters
                  align="start"
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activePromptPaymentDiscounts.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue') 
  },
  mixins: [dateUtilsMixin, validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      promptPaymentDiscount: {
        id: 'NA', // No asignado
        nombre_descuento: null,
        porcentaje_descuento: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      promptPaymentDiscounts: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activePromptPaymentDiscounts() {
      return this.promptPaymentDiscounts.filter(pDiscount => pDiscount.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.promptPaymentDiscount)
    }
  },
  async created() {
    this.promptPaymentDiscount.id_autor = this.userData.id
    this.promptPaymentDiscount.id_institucion_educativa = this.institutionId
    this.promptPaymentDiscounts = await this.fetchPromptPaymentDiscountsByEI(this.institutionId)
  },
  methods: {
    async fetchPromptPaymentDiscountsByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/descuento-pronto-pago-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener descuentos pronto pago por institucion educativa', error);
      }
    },
    async createPromptPaymentDiscount() {
      try {
        const response = await api.post('/administracion/crear-descuento-pronto-pago', {
          nombre_descuento: this.promptPaymentDiscount.nombre_descuento,
          porcentaje_descuento: (this.promptPaymentDiscount.porcentaje_descuento / 100),
          fecha_inicio: this.promptPaymentDiscount.fecha_inicio,
          fecha_fin: this.promptPaymentDiscount.fecha_fin,
          id_institucion_educativa: this.promptPaymentDiscount.id_institucion_educativa,
          id_autor: this.promptPaymentDiscount.id_autor
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear el descuento pronto pago.')
        }
      } catch (error) {
        console.error('Error al intentar crear el descuento pronto pago', error)
        this.errors.push('Error al intentar crear el descuento pronto pago')
      }
    },
    async updatePromptPaymentDiscount() {
      try {
        const response = await api.patch('/administracion/update-descuento-pronto-pago', {
          id: this.promptPaymentDiscount.id,
          nombre_descuento: this.promptPaymentDiscount.nombre_descuento,
          porcentaje_descuento: (this.promptPaymentDiscount.porcentaje_descuento / 100),
          fecha_inicio: this.promptPaymentDiscount.fecha_inicio,
          fecha_fin: this.promptPaymentDiscount.fecha_fin,
          id_institucion_educativa: this.promptPaymentDiscount.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar el descuento pronto pago')
        }
      } catch (error) {
        console.error('Error al intentar actualizar el descuento pronto pago', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deletePromptPaymentDiscount(promptPaymentDiscountId) {
      try {
        const response = await api.post('/administracion/delete-descuento-pronto-pago', {
          id_descuento_pronto_pago: promptPaymentDiscountId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar el descuento pronto pago')
        }
      } catch (error) {
        console.error('Error al intentar eliminar el descuento pronto pago', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updatePromptPaymentDiscount()
        this.toUpdate = false
      } else {
        await this.createPromptPaymentDiscount()
        this.clear()
      }
      this.promptPaymentDiscounts = await this.fetchPromptPaymentDiscountsByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.promptPaymentDiscount = {
        id: 'NA', // No asignado
        nombre_descuento: null,
        porcentaje_descuento: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.promptPaymentDiscount = {
        id: 'NA', // No asignado
        nombre_descuento: null,
        porcentaje_descuento: null,
        fecha_inicio: null,
        fecha_fin: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.promptPaymentDiscount = {...item}
      this.promptPaymentDiscount.porcentaje_descuento = (item.porcentaje_descuento * 100)
      this.promptPaymentDiscount.fecha_inicio = this.getDateWithTimeZeroFullStr(item.fecha_inicio)
      this.promptPaymentDiscount.fecha_fin = this.getDateWithTimeZeroFullStr(item.fecha_fin)
      this.promptPaymentDiscount.id_institucion_educativa = item.institucion_educativa.id
      // console.log('Item a editar:', this.promptPaymentDiscount);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deletePromptPaymentDiscount(itemId)
      this.promptPaymentDiscounts = await this.fetchPromptPaymentDiscountsByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>